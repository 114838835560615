<template>
	<div>
		<p class="m-right-title">个人资料</p>
		<div class="m-form">
			<el-form ref="form" :rules="rules" :model="form" label-width="100px">
				<el-form-item label="姓名" prop="username">
					<el-input v-model="form.username"></el-input>
				</el-form-item>
<!--				<el-form-item label="性别">
					<el-radio-group v-model="form.sex">
						<el-radio label="0">保密</el-radio>
						<el-radio label="1">男</el-radio>
						<el-radio label="2">女</el-radio>
					</el-radio-group>
				</el-form-item>-->
        <el-form-item label="用户分类">
          <el-input v-model="form.user_cate_name" :disabled="true"></el-input>
        </el-form-item>
				<el-form-item label="手机号">
					<el-input v-model="form.phone" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="学历" prop="education">
					<el-input v-model="form.education" placeholder="请填写学历"></el-input>
				</el-form-item>
				<el-form-item label="专业">
					<el-select v-model="form.specialty" placeholder="请选择专业">
						<el-option label="初级" value="1"></el-option>
						<el-option label="中级" value="2"></el-option>
						<el-option label="高级" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="form.password" placeholder="填写密码,默认修改密码"></el-input>
				</el-form-item>
        <el-form-item label="重复密码" prop="password">
          <el-input type="password" v-model="form.password2" placeholder="确认密码"></el-input>
        </el-form-item>
				<el-form-item>
					<div class="u-flex u-row-right u-m-t-40">
						<el-button type="primary" @click="onSubmit" style="background: #D6102A;border: 0;">提交</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		to_link
	} from "@/router/api"
	import {
		updataUserInfo
	} from "@/api/common.js"
	export default {
		data() {
			return {
				rules: {
					username: [{
						required: true,
						message: '姓名不能为空',
						trigger: ['blur', 'change']
					},],
					education: [{
						required: true,
						message: '学历不能为空',
						trigger: ['blur', 'change']
					},]
				},
				form: {
					username: '',
					sex: '1',
					phone: '',
					education: '',
					specialty: '',
					password:'',
					password2:'',
					user_cate_name:''
				}
			}
		},
		computed: {
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		mounted() {
			this.getUserInfo()
		},
		methods: {
			to_link,
			getUserInfo() {
 				let count = 0
				let _time = setInterval(() => {
					if (count == 100) {
						clearInterval(_time)
						return false;
					}
					if (JSON.stringify(this.user_info) != '{}') {
						for (let i in this.form) {
							this.form[i] = String(this.user_info[i])
						}
						this.form['password'] = ''
            console.log(this.user_info,'this.user_info');
						clearInterval(_time)
						return false;
					}
					count += 1
				}, 100)
			},
			onSubmit() {
				this.$refs.form.validate(async (valid)=>{
					if(valid){
						let res = await updataUserInfo(this.form)
						if(res.code == 200){
							this.$message.success(res.message)
							this.$store.dispatch('vuex_get_user_info')
							this.form['password'] = ''
						}else{
							this.$message.error(res.message)
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-right-title {
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}

	.m-form {
		width: 600px;
		margin: 0 auto;
		margin-top: 60px;
		margin-bottom: 100px;
	}
</style>
<style lang="scss">
	.m-form {
		.el-radio__input.is-checked+.el-radio__label {
			color: #D6102A;
		}

		.el-radio__input.is-checked .el-radio__inner {
			border-color: #D6102A;
			background: #D6102A;
		}

		.el-select {
			display: inline-block;
			position: relative;
			width: 100%;
		}
	}
</style>
